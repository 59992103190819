<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <!-- 入荷予定データ取込 -->
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_shipdataimport" />
      <v-form ref="searchForm" lazy-validation>
        <v-container fluid>
          <!-- 検索項目 -->
          <!-- １行目 -->
          <v-row class="search-row">
            <!--  取引先 -->
            <div class="search-autocomplete first-search-item" style="width: 450px">
              <v-autocomplete
                dense
                v-model="searchForm.suppliersSelected"
                :items="searchForm.supplierList"
                :label="$t('label.lbl_supplier')"
                class="txt-single"
                :hint="setSrhSupplier()"
                :rules="[rules.inputRequired]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data" class="nal">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require asterisk-spacer">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷予定日-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="searchForm.outScheduleDate"
                :label="$t('label.lbl_shippingScheduleDate')"
                @change="changeDate('outScheduleDateCal', $event)"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :rules="
                  this.searchForm.outScheduleDateDispFlg
                    ? [rules.yyyymmdd]
                    : [rules.yyyymmdd_create]
                "
                :disabled="isOutScheduleDateInactive"
              ></v-text-field>
            </div>
            <div v-if="searchForm.outScheduleDateDispFlg">
              <span class="require asterisk-spacer">*</span>
            </div>
            <!--カレンダーボタン領域-->
            <div class="calender-btn-area">
              <v-menu
                v-model="searchForm.dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
                :disabled="isOutScheduleDateInactive"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    x-small
                    :disabled="isOutScheduleDateInactive"
                  >
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="searchForm.outScheduleDateCal"
                  @input="searchForm.dateMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevDate('outScheduleDateCal')"
                text
                x-small
                :disabled="isOutScheduleDateInactive"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextDate('outScheduleDateCal')"
                text
                x-small
                :disabled="isOutScheduleDateInactive"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 納品日 -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="searchForm.deliveryDate"
                :label="$t('label.lbl_delDate')"
                @change="changeDate('deliveryDateCal', $event)"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :rules="
                  this.searchForm.referenceDispFlg
                    ? [
                        rules.yyyymmdd,
                        rules.isFutureDate(
                          searchForm.deliveryDate,
                          searchForm.outScheduleDate,
                          $t('label.lbl_shippingScheduleDate')
                        ),
                      ]
                    : [
                        rules.yyyymmdd_create,
                        rules.isFutureDate(
                          searchForm.deliveryDate,
                          searchForm.outScheduleDate,
                          $t('label.lbl_shippingScheduleDate')
                        ),
                      ]
                "
                :disabled="isDeliveryDateInactive"
              ></v-text-field>
            </div>
            <div v-if="searchForm.referenceDispFlg">
              <span class="require asterisk-spacer">*</span>
            </div>
            <!--カレンダーボタン領域-->
            <div class="calender-btn-area">
              <v-menu
                v-model="searchForm.dateMenu2"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
                :disabled="isDeliveryDateInactive"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small :disabled="isDeliveryDateInactive">
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="searchForm.deliveryDateCal"
                  @input="searchForm.dateMenu2 = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevDate('deliveryDateCal')"
                text
                x-small
                :disabled="isDeliveryDateInactive"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextDate('deliveryDateCal')"
                text
                x-small
                :disabled="isDeliveryDateInactive"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 取込ファイル -->
            <div class="file-input">
              <v-file-input
                prepend-icon=""
                :label="$t('label.lbl_importFile')"
                outlined
                dense
                v-model="importfile"
                counter
                :rules="[rules.fileRequired]"
                append-outer-icon="mdi-paperclip"
                @click:append-outer="handleClickAppendOuter"
                ref="vFileInput"
              >
              </v-file-input>
            </div>
            <span class="require asterisk-spacer">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 取込ボタン -->
            <v-btn class="post-btn" @click="search()">
              {{ $t("btn.btn_import") }}
            </v-btn>
          </v-row>

          <!-- ２行目 -->
          <v-row>
            <!-- 品質区分-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="arrivalStatusSelected"
                :items="arrivalStatusList"
                :label="$t('label.lbl_arrivalStatus')"
                class="txt-single"
                persistent-hint
                :rules="[rules.inputRequired]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <span class="require asterisk-spacer">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 責任区分-->
            <v-btn-toggle v-model="isRes" color="blue darken-2" class="toggle black--text">
              <v-btn
                mandatory
                class="res-btn"
                v-for="item in blameDivList"
                :key="item.value"
                :value="item.value"
                small
                v-bind:disabled="isDisabledBlame"
              >
                {{ item.text }}</v-btn
              >
            </v-btn-toggle>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 有償/無償-->
            <div style="float: left">
              <v-btn-toggle
                mandatory
                v-model="isPaid"
                color="blue darken-2"
                id="btn-toggle-btn-padding"
              >
                <v-btn
                  class="paid-btn"
                  v-for="item in freeCostDivList"
                  :key="item.value"
                  :value="item.value"
                  small
                >
                  {{ item.text }}
                </v-btn>
              </v-btn-toggle>
            </div>

            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 無償理由選択プルダウン-->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                class="list-single"
                v-model="freeReasonSelected"
                :items="freeReasonList"
                v-bind:disabled="isDisabledFreeReason"
                :label="$t('label.lbl_freeReason')"
                :rules="isPaid == '02' ? [rules.inputRequired] : []"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>

            <!-- 処理区分-->
            <div class="search-autocomplete first-search-item" style="float: left">
              <v-autocomplete
                dense
                v-model="processDivName"
                :items="processDivList"
                :label="$t('label.lbl_processingdivision')"
                :hint="setStsArrival()"
                class="txt-single"
                persistent-hint
                :rules="[rules.inputRequired]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require asterisk-spacer" style="float: left">*</span>
          </v-row>

          <!-- ３行目 -->
          <v-row>
            <!-- 輸送会社名 -->
            <div class="search-row-exeBtn first-search-item">
              <div style="float: left">
                <v-text-field
                  id="get-shipCampany"
                  outlined
                  dense
                  v-model="truckNm"
                  :label="$t('label.lbl_shippingCompanyName')"
                  class="txt-single"
                  readonly
                  append-icon="mdi-close-circle"
                  @click:append="append(), (truckNm = '')"
                />
              </div>

              <!-- 輸送会社ボタン -->
              <div style="float: left">
                <v-btn color="primary" class="other-btn" v-on:click="openTruck">
                  {{ $t("btn.btn_addShpAdress") }}
                </v-btn>
              </div>
            </div>
          </v-row>

          <!-- ４行目 -->
          <v-row>
            <div class="search-row-exeBtn first-search-item">
              <!--検索結果件数表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">{{
                  $t("label.lbl_total") + "：" + totalCount + $t("label.lbl_number")
                }}</span>
              </div>

              <!-- 空白 -->
              <span class="item-spacer">&nbsp;</span>

              <div class="btn-area">
                <!-- アップロードボタン -->
                <v-btn class="post-btn" @click="confirm()" v-bind:disabled="!activate">{{
                  $t("btn.btn_upload")
                }}</v-btn>
                <!-- 画面クリアボタン -->
                <v-btn class="other-btn" @click="clear()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>

              <!--数量合計領域-->
              <div
                style="
                  float: left;
                  display: flex;
                  align-items: center;
                  width: 66%;
                  flex-direction: row-reverse;
                "
              >
                <div
                  style="
                    margin-left: 85%;
                    font-weight: bold;
                    font-size: large;
                    border-bottom: double;
                  "
                >
                  <!-- 数量合計-->
                  {{ $t("label.lbl_totalQty") + " " + sumCount.toLocaleString() }}
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          disable-filtering
          disable-pagination
          :hide-default-footer="true"
          height="610px"
          :options.sync="sortOptions"
        >
          <template v-slot:[`item.inListNo`]="{ item }">
            <!-- 入荷No -->
            <div v-bind:class="{ isD: item.isInListNoFlg }"></div>
            <div class="listNo-style">{{ item.inListNo }}</div>
          </template>
          <template v-slot:[`item.shipInsDate`]="{ item }">
            <!-- 入荷予定日 -->
            <div v-bind:class="{ isD: item.isShipInsDateFlg }">{{ item.shipInsDate }}</div>
          </template>
          <template v-slot:[`item.deliveryDate`]="{ item }">
            <!-- 納品日 -->
            <div v-bind:class="{ isD: item.isDeliveryDateFlg }">{{ item.deliveryDate }}</div>
          </template>
          <template v-slot:[`item.product`]="{ item }">
            <!-- 品番/付帯品番 -->
            <div v-bind:class="{ isD: item.isItemCdFlg }">
              {{ item.itemCd }}
            </div>
            <div v-bind:class="{ isD: item.isItemNameFlg }">
              {{ item.itemSubCd }}
            </div>
          </template>
          <template v-slot:[`item.inScheduleQuantity`]="{ item }">
            <!-- 数量 -->
            <div v-bind:class="{ isD: item.isInQtyeFlg }">
              {{ item.inScheduleQuantity.toLocaleString() }}
            </div>
          </template>
          <template v-slot:[`item.fromName`]="{ item }">
            <!-- 入荷元 -->
            <div v-bind:class="{ isD: item.isFromNameFlg }">{{ item.fromName }}</div>
          </template>
        </v-data-table>
        <!-- 詳細画面ポップアップ -->
        <v-dialog v-model="dialogDetails" :max-width="2000" persistent no-click-animation>
          <ShipDataImportCsvError
            :isOpenDetailDialog.sync="dialogDetails"
            :entity="detailDialogData"
            class="overflow"
            v-on:closePopup="closePopup"
          />
        </v-dialog>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :screenFlag="infoDialog.screenFlag"
        :homePageFlag="infoDialog.homePageFlag"
        :shpSchListFlg="infoDialog.shpSchListFlg"
        :shpSchAddFlg="infoDialog.shpSchAddFlg"
        :firstPageFlag="infoDialog.firstPageFlag"
      />

      <!-- メッセージダイアログ-->
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :screenFlag="ConfirmDialog.screenFlag"
        :okAction="csvConfirm"
      />
      <v-dialog v-model="isOpenTruck" :max-width="800">
        <Truck
          :isOpenDetailDialog.sync="isOpenTruck"
          @click="saveTruck"
          :truckInfo="truckInfo"
          ref="truck"
        />
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import NavBar from "../../components/NavBar.vue";
import Truck from "../../views/common/Truck";
import ShipDataImportCsvError from "../ship/ShipDataImportCsvError.vue";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";
import commonMixinExcel from "@/mixins/CommonMixinExcel";

export default {
  name: appConfig.SCREEN_ID.P_SHP_002,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    sideMenu,
    NavBar,
    Truck,
    ShipDataImportCsvError,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  mixins: [commonMixin, commonRules, commonMixinExcel],
  data: () => ({
    loadingCounter: 0,
    sortItem: "",
    sortOptions: {},
    importfile: {},
    searchForm: {
      // 取引先
      suppliersSelected: "",
      supplierList: [],
      // 出荷予定日
      outScheduleDate: "",
      outScheduleDateCal: "",
      dateMenu: false,
      outScheduleDateDispFlg: false,
      outScheduleDateInactiveFlg: false,
      // 納品日
      deliveryDate: "",
      deliveryDateCal: "",
      dateMenu2: false,
      referenceDispFlg: false,
      deliveryDateInactiveFlg: false,
    },
    // ファイル名
    fileName: {},
    // S3ファイル名
    s3FileName: {},
    // 責任
    isRes: "01",
    isResBadValue: "01",
    // 有償/無償
    isPaid: "01",
    // 輸送会社名（画面表示用）
    truckNm: "",
    // 輸送会社区分（登録用）
    truckKbn: "",
    // 輸送会社名（登録用）
    truck: "",
    // 総件数
    totalCount: 0,
    // 数量件数
    sumCount: "",
    // 品質区分
    arrivalStatusSelected: "01",
    isDisabledFreeReason: true,
    isDisabledBlame: true,
    // 取引先CD
    clientCd: "",
    // 品質区分
    qualityDiv: "",
    // 責任区分
    blameDiv: "",
    // 変更理由区分
    changeReasonDiv: "",
    // 有償無償区分
    freeCostDiv: "",
    // 有償無償理由区分
    freecostReasonDiv: "",
    // 運送会社区分
    carrierCompKbn: "",
    // 運送会社SID
    carrierCompSid: "",
    // 処理区分
    processDiv: "",
    // 無償理由
    freeReasonSelected: "",
    // 処理区分
    processDivName: "01",
    // 入荷予定配列
    inListCsvData: [],
    // 取込確定フラグ
    confirmFlg: false,
    // メニュー
    openMenu: null,
    // 表示の切り替え
    activate: false,
    // 配送先情報画面ダイアログ
    isOpenTruck: false,
    truckInfo: { truck: "" },
    // エラー画面遷移用取引先cd,name
    supplierCdName: "",
    freeCostDivList: [],
    blameDivList: [],
    arrivalStatusList: [],
    freeReasonList: [],
    processDivList: [],
    // 詳細ポップアップ
    dialogDetails: false,
    errorDetailDialogData: [],
    // 詳細ERROR画面へ渡す
    detailDialogData: {
      suppliersSelectedName: "",
      list: [],
    },
    // シート数
    sheetNum: 0,
    // 取込データ
    output: [],
    sheetInfo: [],
    // ヘッダ
    headerItems: [
      // No
      {
        text: "No",
        value: "no",
        width: "4%",
        sortable: true,
        align: "right",
      },
      // 出荷No
      {
        text: i18n.tc("label.lbl_shippingNo"),
        value: "inListNo",
        width: "17%",
        align: "left",
        sortable: true,
      },
      // 出荷予定日
      {
        text: i18n.tc("label.lbl_shippingScheduleDate"),
        value: "shipInsDate",
        width: "8%",
        align: "left",
        sortable: true,
      },
      // 納品日
      {
        text: i18n.tc("label.lbl_delDate"),
        value: "deliveryDate",
        width: "8%",
        align: "left",
        sortable: true,
      },
      // 品番/付帯品番
      {
        text: i18n.tc("label.lbl_productCode") + "/" + i18n.tc("label.lbl_accessoryProduct"),
        value: "product",
        width: "16%",
        align: "left",
        sortable: true,
      },
      // 品名
      {
        text: i18n.tc("label.lbl_productCodeName"),
        value: "itemName",
        width: "16%",
        align: "left",
        sortable: false,
      },
      // 数量
      {
        text: i18n.tc("label.lbl_quantity"),
        value: "inScheduleQuantity",
        width: "5%",
        align: "right",
        sortable: true,
      },
      // 出荷先
      {
        text: i18n.tc("label.lbl_shippingTo"),
        value: "toName",
        width: "20%",
        align: "left",
        sortable: true,
      },
    ],
    // ダミーデータ
    inputList: [],
    rules: {
      freeReasonInputRequired: (value) =>
        (!!value && this.isDisabledFreeReason == false) || i18n.tc("check.chk_select"),
    },
  }),
  methods: {
    // ダイアログクローズ
    closePopup() {
      this.dialogDetails = false;
    },
    append() {
      this.truckKbn = "";
    },
    /**
     * 初期表示
     */
    init() {
      // 取引先取得
      this.getCustomInfo();
      // マスタ取得
      this.getMstCode();
    },
    /**
     * 機能権限管理取得
     */
    getFunctionPermissions() {
      // 取引先が未選択の場合は、基準日初期化して終了
      if (!this.searchForm.suppliersSelected) {
        this.initOutScheduleDate();
        return;
      }

      // ローディング画面表示ON
      this.loadingCounter = 1;

      // PC画面制御
      const functionPermissions = getParameter.getFunctionPermissions({
        conditions: [
          {
            // 納品日
            clientSid: this.searchForm.suppliersSelected,
            attachResourceKey: appConfig.SCREEN_ID.P_SHP_002,
            item01: "DeliveryDate",
          },
          {
            // 出荷予定日
            clientSid: this.searchForm.suppliersSelected,
            attachResourceKey: appConfig.SCREEN_ID.P_SHP_002,
            item01: "ShippingScheduleDate",
          },
          {
            // 出荷予定日非活性
            clientSid: this.searchForm.suppliersSelected,
            attachResourceKey: appConfig.SCREEN_ID.P_SHP_002,
            item01: "InactiveShippingScheduleDate",
          },
          {
            // 納品日非活性
            clientSid: this.searchForm.suppliersSelected,
            attachResourceKey: appConfig.SCREEN_ID.P_SHP_002,
            item01: "InactiveDeliveryDate",
          },
        ],
      });
      Promise.all([functionPermissions])
        .then((result) => {
          this.initOutScheduleDate();
          if (result[0].find((permission) => permission.item01 == "DeliveryDate")) {
            this.searchForm.referenceDispFlg = true;
          } else {
            this.searchForm.referenceDispFlg = false;
          }
          if (result[0].find((permission) => permission.item01 == "ShippingScheduleDate")) {
            this.searchForm.outScheduleDateDispFlg = true;
          } else {
            this.searchForm.outScheduleDateDispFlg = false;
          }
          if (result[0].find((permission) => permission.item01 == "InactiveShippingScheduleDate")) {
            this.searchForm.outScheduleDateInactiveFlg = true;
          } else {
            this.searchForm.outScheduleDateInactiveFlg = false;
          }
          if (result[0].find((permission) => permission.item01 == "InactiveDeliveryDate")) {
            this.searchForm.deliveryDateInactiveFlg = true;
          } else {
            this.searchForm.deliveryDateInactiveFlg = false;
          }
        })
        .then(() => {
          // 出荷予定日が必須の場合、基準日の初期値取得
          if (this.searchForm.outScheduleDateDispFlg) {
            this.searchForm.outScheduleDateCal = dateTimeHelper.dateCalc(
              dateTimeHelper.convertJST()
            );
          }
        })
        .catch((ex) => {
          this.initOutScheduleDate();
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    /**
     * 基準日初期化
     */
    initOutScheduleDate() {
      this.searchForm.outScheduleDateDispFlg = false;
      this.searchForm.outScheduleDateInactiveFlg = false;
      this.searchForm.referenceDispFlg = false;
      this.searchForm.deliveryDateInactiveFlg = false;
      this.searchForm.outScheduleDate = "";
      this.searchForm.outScheduleDateCal = "";
      this.searchForm.deliveryDate = "";
      this.searchForm.deliveryDateCal = "";
    },
    /**
     * 日付を－１日
     */
    prevDate(modelName) {
      if (this.searchForm[modelName] == null || this.searchForm[modelName] == "") {
        return;
      }
      let date = new Date(this.searchForm[modelName]);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.searchForm[modelName] != toDate) {
        this.searchForm[modelName] = toDate;
      } else {
        this.searchForm[modelName] = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },
    /**
     * 日付を＋１日
     */
    nextDate(modelName) {
      if (this.searchForm[modelName] == null || this.searchForm[modelName] == "") {
        return;
      }
      let date = new Date(this.searchForm[modelName]);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.searchForm[modelName] != toDate) {
        this.searchForm[modelName] = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.searchForm[modelName] = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },
    /**
     * 日付入力
     */
    changeDate(modelName, val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.searchForm[modelName] = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.searchForm[modelName] = null;
      }
    },
    /**
     * 初期データ取得
     */
    getCustomInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC()
      );
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.searchForm.supplierList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    /**
     * 取込確定ボタン押下
     */
    confirm() {
      // ボタン識別フラグ（trueだったら、取込確定）
      this.confirmFlg = true;
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_importConfirm"),
      ]);
    },
    // 処理区分
    setStsArrival() {
      for (var i = 0; i < this.processDivList.length; i++) {
        if (this.processDivList[i].value == this.processDivName) {
          return this.processDivList[i].name;
        }
      }
    },
    /**
     * 画面クリア押下
     */
    clear() {
      // ボタン識別フラグ（falseだったら、画面クリア）
      this.confirmFlg = false;
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
    },
    /**
     * アップロード後画面クリア
     */
    afterUploadClear() {
      // 画面クリア処理
      this.inputList = [];
      this.importfile = null;
      this.totalCount = 0;
      this.sumCount = "";
      this.arrivalStatusSelected = "01";
      this.activate = false;
      this.freeReasonSelected = "";
      this.processDivName = "01";
      this.isPaid = "01";
      this.truckNm = "";
      this.truckInfo = { truck: "" };
      this.truck = "";
      this.truckKbn = "";
      // 取引先
      this.searchForm.suppliersSelected = "";
      // 出荷予定日
      this.searchForm.outScheduleDate = "";
      this.searchForm.outScheduleDateCal = "";
      // 納品日
      this.searchForm.deliveryDate = "";
      this.searchForm.deliveryDateCal = "";

      // バリデーションチェックをリセット
      this.$refs.searchForm.resetValidation();
    },
    /**
     * 取込ボタン押下処理
     */
    search() {
      // 取込前のバリデーションチェック
      if (this.$refs.searchForm.validate()) {
        // 取込確定処理
        // 選択したファイルを読み込む
        const selectFile = this.importfile;
        // 拡張子チェック
        let fileType = selectFile.type;
        // 取り込んだファイルはCSVの場合
        if (fileType == "text/csv") {
          this.importCSV(selectFile, true)
            .then((result) => {
              this.inListCsvData = result;
            })
            .then(() => {
              this.postCsv();
            });
        } else if (
          fileType == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          fileType == "application/vnd.ms-excel"
        ) {
          // 取り込んだファイルはエクセルの場合
          this.importExcel(selectFile)
            .then((result) => {
              this.inListCsvData = result;
            })
            .then(() => {
              this.postCsv();
            });
        } else {
          // 取り込んだファイルはCSVまたはExcel以外場合エラーメッセージを表示する
          this.infoDialog.message = i18n.tc("check.chk_limitSelectFileType");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
          return;
        }
      }
    },
    /**
     * マスタコード取得
     */
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 品質区分
      const qualityList = getParameter.getCodeMst(appConfig.CODETYPE.IN_QUALITY_DIV);
      // 理由コード
      const reasonList = getParameter.getCodeMst(appConfig.CODETYPE.IN_REASON_DIV);
      // 有償無償区分
      const freeCostList = getParameter.getCodeMst(appConfig.CODETYPE.IN_FREECOST_DIV);
      // 責任区分
      const blameList = getParameter.getCodeMst(appConfig.CODETYPE.IN_BLAME_DIV);
      // 処理区分
      const processList = getParameter.getCodeMst(appConfig.CODETYPE.IN_PROCESS_DIV);

      Promise.all([qualityList, reasonList, freeCostList, blameList, processList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.arrivalStatusList = result[0];
          this.freeReasonList = result[1];
          this.freeCostDivList = result[2];
          // 責任区分の最初のなしを削除
          result[3].splice(0, 1);
          this.blameDivList = result[3];
          this.processDivList = result[4];
          this.processDivName = this.processDivList[0].value;
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    /**
     * POSTAPI(本登録POST)
     */
    csvConfirm() {
      if (this.confirmFlg) {
        if (this.$refs.searchForm.validate()) {
          // ローディング画面表示ON
          this.loadingCounter = 1;
          const body = this.$httpClient.createRequestBodyConfig();

          //  画面ID TODO:修正必要
          body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SHP_002;

          // 取引先SID
          body.reqIdv.clientSid = this.searchForm.suppliersSelected;
          // ファイル名
          body.reqIdv.fileName = this.importfile.name;
          // 入荷予定配列
          body.reqIdv.listCsvData = this.inListCsvData;
          // タイムゾーン
          body.reqIdv.timeZone = dateTimeHelper.getTimeZone();
          // 入出荷フラグ(0004)
          body.reqIdv.inOutDiv = "0004";

          // 取引先CD
          body.reqIdv.clientCd = this.clientCd;
          // 品質区分
          body.reqIdv.qualityDiv = this.arrivalStatusSelected;
          // 責任区分
          body.reqIdv.blameDiv = this.isRes;
          // 変更理由区分
          body.reqIdv.changeReasonDiv = this.changeReasonDiv;
          // 有償無償区分
          body.reqIdv.freeCostDiv = this.isPaid;
          // 有償無償理由区分
          body.reqIdv.freecostReasonDiv = this.freeReasonSelected;
          // 出荷予定日
          body.reqIdv.outScheduleDate = this.searchForm.outScheduleDate;
          // 納品日
          body.reqIdv.deliveryDate = this.searchForm.deliveryDate;

          // 路線業者SID（輸送会社名）
          if (this.truckKbn != "") {
            body.reqIdv.carrierCompKbn = this.truckKbn;
            if (this.truck != "") {
              body.reqIdv.carrierCompSid = this.truck;
            }
          }
          if (this.truck === "") {
            body.reqIdv.carrierCompSid = "";
          }
          // 処理区分（画面上にないから追加）
          body.reqIdv.processDiv = this.processDivName;

          // 品質区分
          body.reqIdv.qualityDiv = this.arrivalStatusSelected;
          // 処理区分
          body.reqIdv.processDiv = this.processDivName;

          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .securePost(appConfig.API_URL.BIZ_INSCHDULESFILEMAIN, body, appConfig.APP_CONFIG)
              .then((response) => {
                const jsonData = JSON.parse(JSON.stringify(response.data));
                // 正常時
                if (jsonData.resCom.resComCode == "000") {
                  this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
                  this.infoDialog.title = "結果";
                  this.infoDialog.isOpen = true;
                  this.infoDialog.screenFlag = true;
                  // 画面遷移ボタン TODO：修正する
                  this.infoDialog.homePageFlag = true;
                  this.infoDialog.shpSchListFlg = true;
                  this.infoDialog.shpSchAddFlg = true;
                  this.infoDialog.firstPageFlag = false;
                  this.clearFlg = true;
                  resolve(response);
                } else {
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = true;
                  // エラー時
                  // const errorList = [];
                  // エラー情報
                  // jsonData.resIdv.errors.forEach((row) => {
                  //   errorList.push({
                  //     // 行数
                  //     row: row.row,
                  //     // 項目数
                  //     columnName: row.columnName,
                  //   });
                  // });
                  reject();
                }
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;

                reject();
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
                this.afterUploadClear();
              });
          });
        }
      } else {
        // 画面クリア処理
        this.inputList = [];
        this.importfile = null;
        this.totalCount = 0;
        this.sumCount = "";
        this.arrivalStatusSelected = "01";
        this.activate = false;
        this.freeReasonSelected = "";
        this.processDivName = "01";
        this.isPaid = "01";
        this.truckNm = "";
        this.truckInfo = { truck: "" };
        this.truck = "";
        this.truckKbn = "";
        // 取引先
        this.searchForm.suppliersSelected = "";
        // 出荷予定日
        this.searchForm.outScheduleDate = "";
        this.searchForm.outScheduleDateCal = "";
        // 納品日
        this.searchForm.deliveryDate = "";
        this.searchForm.deliveryDateCal = "";

        // バリデーションチェックをリセット
        this.$refs.searchForm.resetValidation();
      }
    },
    isErr() {
      let countErr = 0;
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].isInListNoFlg) {
          countErr += 1;
        }
      }
      if (countErr == 0) {
        this.activate = true;
      }
    },
    /**
     * POSTAPI(仮登録POST)
     */
    postCsv() {
      // 数量合計を空にする
      this.sumCount = "";

      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SHP_002;

      // 取引先SID
      body.reqIdv.clientSid = this.searchForm.suppliersSelected;
      // ファイル名
      body.reqIdv.fileName = this.importfile.name;
      // 入荷予定配列
      body.reqIdv.listCsvData = this.inListCsvData;
      // タイムゾーン
      body.reqIdv.timeZone = dateTimeHelper.getTimeZone();
      // 入出荷フラグ(0004)
      body.reqIdv.inOutDiv = "0004";

      // 取引先CD
      body.reqIdv.clientCd = this.clientCd;
      // 品質区分
      body.reqIdv.qualityDiv = this.arrivalStatusSelected;
      // 責任区分
      body.reqIdv.blameDiv = this.isRes;
      // 変更理由区分
      body.reqIdv.changeReasonDiv = this.changeReasonDiv;
      // 有償無償区分
      body.reqIdv.freeCostDiv = this.isPaid;
      // 有償無償理由区分
      body.reqIdv.freecostReasonDiv = this.freeReasonSelected;
      // 出荷予定日
      body.reqIdv.outScheduleDate = this.searchForm.outScheduleDate;
      // 納品日
      body.reqIdv.deliveryDate = this.searchForm.deliveryDate;

      // 路線業者SID（輸送会社名）
      if (this.truckKbn != "") {
        body.reqIdv.carrierCompKbn = this.truckKbn;
        if (this.truck != "") {
          body.reqIdv.carrierCompSid = this.truck;
        }
      }
      if (this.truck === "") {
        body.reqIdv.carrierCompSid = "";
      }
      // 運送会社区分
      // body.reqIdv.carrierCompKbn = this.truckKbn;
      // 運送会社SID
      // body.reqIdv.carrierCompSid = this.truck;
      // 処理区分
      body.reqIdv.processDiv = this.processDivName;

      if (this.sortItem != "") {
        body.reqCom.reqComSortItem = this.sortItem;
      }

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_INSCHDULESFILE, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              if (jsonData.resIdv.schedulesFileTmp.csvErrorCheckResultList.length != 0) {
                const errorList = [];
                this.errorDetailDialogData = [];
                jsonData.resIdv.schedulesFileTmp.csvErrorCheckResultList.forEach((row) => {
                  errorList.push({
                    listNo: row.listNo,
                    row: row.row,
                    column: row.column,
                    message: row.message,
                    value: row.value,
                  });
                  this.errorDetailDialogData = errorList;
                });
                // エラー一覧タブが開く
                // 詳細画面へ渡す値を設定します。
                this.detailDialogData.list = this.errorDetailDialogData;
                // 選択された列名
                this.detailDialogData.suppliersSelectedName = this.setSrhSupplier();
                this.detailDialogData.supplierCdName = this.supplierCdName;

                this.dialogDetails = true;
              } else {
                const list = [];
                // ファイル名
                this.fileName = jsonData.resIdv.schedulesFileTmp.fileName;
                // S3登録ファイル名
                this.s3FileName = jsonData.resIdv.schedulesFileTmp.s3FileName;
                // 画面表示項目
                jsonData.resIdv.schedulesFileTmp.scheduleCsvResults.forEach((row) => {
                  let isErrFlg = false;
                  let errMsg = "";

                  if (row.errMsg) {
                    isErrFlg = true;
                    errMsg = row.errMsg;
                    errMsg = errMsg.replace("\r\n", "<br />");
                    this.activate = false;
                  }
                  list.push({
                    isInListNoFlg: isErrFlg,
                    isShipInsDateFlg: isErrFlg,
                    isDeliveryDateFlg: isErrFlg,
                    isItemCdFlg: isErrFlg,
                    isItemNameFlg: isErrFlg,
                    isInQtyeFlg: isErrFlg,
                    isFromNameFlg: isErrFlg,
                    isAddInfoFlg: isErrFlg,
                    // No
                    no: row.no,
                    // 入荷No
                    inListNo: row.listNo,
                    // 入荷予定日
                    shipInsDate: this.convertDate(row.scheduleDate),
                    // 納品日
                    deliveryDate: this.convertDate(row.deliveryDate),
                    // 品番
                    itemCd: row.itemCd,
                    // 付帯品番
                    itemSubCd: row.itemSubCd ?? "",
                    // 品名
                    itemName: row.itemName,
                    // ロット
                    lotNo: row.lotNo,
                    // 数量
                    inScheduleQuantity: row.scheduleQuantity,
                    // 入荷元
                    fromName: row.fromName,
                    // 入荷元SID
                    fromSid: row.fromSid,
                    // 出荷先
                    toName: row.toName,
                    // 出荷先SID
                    toSid: row.toSid,
                    // 付帯情報
                    // addInfo: row.addInfo,
                    accInfos: errMsg,
                    csvProcessDiv: row.csvProcessDiv,
                    // errInList:0か1か入って来てエラーか判断する
                  });
                });

                // 画面表示用リストに格納
                this.inputList = list;
                this.isErr();
                this.totalCount = this.inputList.length;
                for (let i = 0; i < list.length; i++) {
                  this.sumCount = Number(this.sumCount) + Number(list[i].inScheduleQuantity);
                }
                resolve(response);
              }
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;

            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.searchForm.supplierList.length; i++) {
        if (this.searchForm.supplierList[i].value == this.searchForm.suppliersSelected) {
          return this.searchForm.supplierList[i].name;
        }
      }
    },
    /**
     * 取引先コー取得
     */
    getSupplierCd() {
      for (var i = 0; i < this.searchForm.supplierList.length; i++) {
        if (this.searchForm.supplierList[i].value == this.searchForm.suppliersSelected) {
          return this.searchForm.supplierList[i].officeCd;
        }
      }
    },
    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },
    /**
     * 配送先選択画面：配送先選択ボタン処理
     */
    openTruck() {
      if (this.searchForm.suppliersSelected) {
        //取引先SID
        this.truckInfo.suppliersSelected = this.searchForm.suppliersSelected;
        //路線業者SID
        this.truckInfo.deliveryTraderSid = this.truck;
        //輸送業者コード
        this.truckInfo.carrierCompCd = this.truckKbn;
        this.isOpenTruck = true;
        if (this.$refs.truck != undefined) {
          //表示時処理
          this.$refs.truck.init();
        }
      } else {
        this.searchForm.suppliersSelected = null;
      }
    },
    /**
     * 配送先選択画面：登録後処理
     */
    saveTruck(value) {
      this.truckInfo = value;
      // 画面表示用
      this.truckNm = this.truckInfo.carrierCompName;
      // API検索用
      this.truckKbn = this.truckInfo.carrierCompCd;
      this.truck = this.truckInfo.deliveryTraderSid;
      this.isOpenTruck = false;
    },
    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    /**
     * アイコンを押下しても、エクスプローラーが開く処理
     */
    handleClickAppendOuter() {
      this.$refs.vFileInput.$el.querySelector("input").click();
    },
    /**
     * 日付フォーマット変換
     */
    convertDate(val) {
      if (val) {
        if (val.length == 8) {
          return val.substr(0, 4) + "/" + val.substr(4, 2) + "/" + val.substr(6, 2);
        } else if (val.length == 6) {
          return val.substr(0, 2) + "/" + val.substr(2, 2) + "/" + val.substr(4, 2);
        } else {
          return val.substr(0, 4) + "/" + val.substr(5, 2) + "/" + val.substr(8, 2);
        }
      } else {
        // 値がない場合は、空を返却
        return "";
      }
    },
  },
  computed: {
    isOutScheduleDateInactive: function () {
      return this.searchForm.outScheduleDateInactiveFlg;
    },
    isDeliveryDateInactive: function () {
      return this.searchForm.deliveryDateInactiveFlg;
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    "searchForm.outScheduleDateCal": function (newVal) {
      this.searchForm.outScheduleDate = this.formatDate(newVal);
    },
    "searchForm.deliveryDateCal": function (newVal) {
      this.searchForm.deliveryDate = this.formatDate(newVal);
    },
    isPaid: function (newVal) {
      if (newVal == "02") {
        this.isDisabledFreeReason = false;
      } else {
        this.isDisabledFreeReason = true;
        this.freeReasonSelected = "";
        this.processDivName = "01";
      }
    },
    arrivalStatusSelected: function (newVal) {
      if (newVal == "02") {
        this.isDisabledBlame = false;
        this.isRes = this.isResBadValue;
      } else {
        this.isDisabledBlame = true;
        this.isResBadValue = this.isRes;
        this.isRes = "01";
      }
    },
    "searchForm.suppliersSelected": function () {
      // 輸送会社名クリア
      this.truckNm = "";
      this.truckKbn = "";
      this.truck = "";
      for (let i = 0; i < this.searchForm.supplierList.length; i++) {
        if (this.searchForm.suppliersSelected == this.searchForm.supplierList[i].value) {
          this.supplierCdName = this.searchForm.supplierList[i].text;
          this.clientCd = this.searchForm.supplierList[i].clientCd;
        }
      }
      this.$refs.searchForm.resetValidation();
      this.getFunctionPermissions();
    },
    sortOptions: {
      handler(items) {
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? "desc" : "asc";
          this.sortItem = sortBy + " " + ascdesc;
          if (sortBy == "no" || sortBy == "inScheduleQuantity") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                return a[sortBy] - b[sortBy];
              } else {
                return b[sortBy] - a[sortBy];
              }
            });
          } else if (
            sortBy == "inListNo" ||
            sortBy == "shipInsDate" ||
            sortBy == "deliveryDate" ||
            sortBy == "toName"
          ) {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a[sortBy] < b[sortBy]) {
                  return -1;
                } else if (a[sortBy] > b[sortBy]) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a[sortBy] > b[sortBy]) {
                  return -1;
                } else if (a[sortBy] < b[sortBy]) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          } else if (sortBy == "product") {
            this.inputList.sort((a, b) => {
              if (ascdesc == "asc") {
                if (a.itemCd < b.itemCd) {
                  return -1;
                } else if (a.itemCd > b.itemCd) {
                  return 1;
                } else {
                  return 0;
                }
              } else {
                if (a.itemCd > b.itemCd) {
                  return -1;
                } else if (a.itemCd < b.itemCd) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          }
          return this.inputList;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
/* 画面優先順位 */
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

/* 送り先登録時、色変更 */
.isD {
  color: $color-error !important;
}

/* 備考欄、テキストボックス */
.search-textbox-remark {
  width: 40rem;
}
</style>
